import React from 'react'
import greetingVideo from "./greetingVideo.mp4"

import css from './Video.module.css';

const Video = () => {
    return <div className={css.mainWrapper}>
     <video autoPlay loop muted playsInline src={greetingVideo}/>

    </div>
}

export default Video