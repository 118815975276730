import React from 'react'
// import wave from "./wave.mp4"
import man from "./man.png"

import css from './WhoWeAre.module.css';

const WhoWeAre = () => {
    return (
        <div className={css.wrapper1}>
            <div className={css.wrapper2}>
                <div>
                    <div className={css.title}>WHO WE ARE.</div>
                    <div className={css.paragraph}>MUXIC.IO is brought to you by a team of 15-year industry veterans hailing from the I.T. solutions and Entertainment space. We aim to revolutionise music for the next generation.</div>
                </div>
                <img src={man} alt="man" />
            </div>
        </div>
    )
}

export default WhoWeAre