import logo from "./muxic_logo.png"
import { useNavigate } from "react-router-dom"

import css from './FAQ.module.css'

function FAQ() {
    const navigate = useNavigate()
    return (
        <div className={css.parallax}>
            <div className={css.wrapper1}>
                <img
                    className={css.logo}
                    src={logo}
                    alt="logo" />
                <br />
                <p class="c6"><span class="c2">Listen to Earn &ndash; FAQ</span></p>
                <br />
                <p class="c6"><span class="c2">Q: How can I earn Listen to Earn points?</span></p>
                <p class="c6"><span class="c2">A: Listen to Earn points can be earned by listening to playlists on our Muxic app.
                    Completing the listening of one song earns the listener 100 song points. Incomplete listening does not earn
                    any song points. Repeating the listening of a song within 24 hours will not earn any additional
                    points.</span></p>
                <br />
                <p class="c1"><span class="c2"></span></p>
                <p class="c6"><span class="c2">Q: How many songs can a playlist contain?</span></p>
                <p class="c6"><span class="c2">A: A playlist can contain between 1 and 100 songs.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: How many playlists can I create?</span></p>
                <p class="c6"><span class="c2">A: Each user can create a maximum of 50 playlists at any given time period.</span>
                </p>
                <br />
                <p class="c6"><span class="c2">Q: How can I earn points by sharing a playlist with a friend?</span></p>
                <p class="c6"><span class="c2">A: By sharing a self-created playlist with a friend, the creator of the playlist will
                    receive 20 song points for every song listened. Please note that a song contained in multiple playlists
                    shared and listened to from user to friends will earn respective points (20 for the creator and 100 for the
                    listener) only once in a single day. However, re-earning points is possible if the song is re-listened to or
                    re-sent on another day.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: How can I share a playlist with my friends?</span></p>
                <p class="c6"><span class="c2">A: Sharing a playlist with friends is easy. You can search for users by email and
                    send them a friend request. The sender can choose automatic or manual acceptance of friend requests.
                    Alternatively, you can create your playlist and share it with friends within the app or copy the playlist
                    link to share outside the app.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: Can I earn more Muxic Song Points and participate in other campaigns?</span></p>
                <p class="c6"><span class="c2">A: The awarding of more Muxic Song Points and participation in other Muxic point
                    campaigns are subject to Muxic&#39;s sole decisions and may be subject to changes.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: How can I redeem my Song Points?</span></p>
                <p class="c6"><span class="c2">A: Song Points earned can be utilized to redeem Muxic products, discount vouchers,
                    games, subscriptions, and more.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: How are Muxic rewards given upon redemption?</span></p>
                <p class="c6"><span class="c2">A: Upon redemption, Muxic rewards are given in the form of digital voucher codes and
                    are available on a &quot;while stocks last, first-come-first-serve&quot; basis. No reservation of rewards is
                    allowed.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: When does a new day start for earning points?</span></p>
                <p class="c6"><span class="c2">A: A new day starts at AM 04:00 HKT for earning points.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: Can I exchange Song Points for cash?</span></p>
                <p class="c6"><span class="c2">A: Song Points cannot be exchanged for cash and are non-refundable once expired or
                    deducted after a reward redemption has been conducted.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: How will scheduled maintenance affect Song Points and reward redemption?</span>
                </p>
                <p class="c6"><span class="c2">A: Muxic ID, Song Points, and other software and services may undergo scheduled
                    maintenance regularly to conduct improvements. During these periods, earning Song Points and/or redeeming
                    rewards may be affected. In the unlikely event of an unforeseen services outage, Muxic will be unable to
                    provide you with any Song Points in return.</span></p>
                <br />
                <p class="c6"><span class="c2">Q: Can Muxic change or terminate the campaigns?</span></p>
                <p class="c6"><span class="c2">A: Muxic Listen to Earn reserves the right to change, amend, modify, suspend,
                    continue, or terminate all or any part of Muxic campaigns either in an individual case or in general, at any
                    time without notice.</span></p>
                <br />
                <div
                    style={{ "cursor": "pointer" }}
                    onClick={() => { navigate("/") }} >Return to muxic.io</div>
                <br />
            </div>

        </div>
    )
}

export default FAQ