import React from 'react'
import css from './Nftnella.module.css'
import logo from 'assets/logo_white.png'

import mainVideo from 'assets/nftella/NFTELLA.mp4'

const Nftnella = () => {
  return (
    <div className={css.wrapper1}>
      <img src={logo}/>
      <a target="_blank" href="https://www.klook.com/zh-HK/event-detail/102000129-muxic-7daywknd/">
        <button>PURCHASE TICKETS</button>
      </a>

      <div className={css.videoWrapper}>
        <video src={mainVideo} autoPlay loop playsInline muted />
      </div>

    </div>

  )
}

export default Nftnella