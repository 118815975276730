import React from 'react'
import building from "./building.png"
import talent from "./talent.png"
import numbers from "./numbers.png"


import css from './Fringe.module.css';

const Fringe = () => {
    return (
        <div className={css.wrapper1}>
            <div className={css.wrapper2}>
                <div className={css.textWrapper}>
                    <div className={css.title}>Artist MANAGEMENT + Community: FRINGE CLUB INCUBATION PROGRAM</div>
                </div>
                <img className={css.talent} src={talent} />
                <div className={css.statsWrapper}>
                    <img className={css.numbers} src={numbers} />
                    <img className={css.building} src={building} />
                </div>
            </div>




        </div>
    )
}

export default Fringe