import Email from 'components/email/Email'
import Video from 'components/video/Video'
import WhoWeAre from 'components/whoWeAre/WhoWeAre'
import VisionMission from 'components/visionMission/VisionMission'
import Leadership from 'components/leadership/Leadership'
import Mr1 from 'components/mr1/Mr1'
import Bam from 'components/bam/Bam'
import Fringe from 'components/fringe/Fringe'
import Spiral from 'components/spiral/Spiral'
import Kole from 'components/kole/Kole'
import NewApp from 'components/newApp/NewApp'


import css from './Home.module.css'


function Home() {

    // function componentSwitcher () {
    //     switch (greetingClicked) {
    //         case true: return <Email/>
    //         default: return <Greeting/>
    //     }
    // }


    return (
        <div className={css.parallax}>
            <Video />
            <WhoWeAre />
            <VisionMission />
            <Leadership />
            <Mr1 />
            <Bam />
            <Fringe />
            <Spiral />
            <Kole />
            <NewApp />
            {/* <Email /> */}
        </div>

    )

}

export default Home