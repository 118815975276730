import React from 'react'
import man2 from "./man2.png"

import css from './VisionMission.module.css';

const VisionMission = () => {
    return <div className={css.wrapper1}>
        <div>
            <img src={man2} alt="man" />
        </div>
        <div className={css.wrapper2}>
            <div>
                <div className={css.title}>VISION.</div>
                <div className={css.paragraph}>Hong Kong's first music sharing platform for the next generation of entertainment, empowering creators and their fans to seamlessly immerse themselves into the Music Metaverse and create a new form of user experience and music ecosystem.</div>
            </div>
            <div>
                <div className={css.title}>MISSION.</div>
                <div className={css.paragraph}>Be the top key player in the music industry with signature in music metaverse and our team of virtual idols artists. </div>
            </div>


        </div>

    </div>
}

export default VisionMission