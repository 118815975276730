import React from 'react'
import stats from "./stats.png"
import banner from "./banner.jpeg"
import ceo from "./ceo.png"
import css from './Bam.module.css';

const Bam = () => {
  return (
    <div className={css.wrapper1}>
      <div className={css.wrapper2}>
        <div className={css.title}>
          《BAM》OPENING |
          MERCEDES BENZ VIRTUAL AVATAR REMOTE KEYNOTE:
          CEO Andreas Buchenthal</div>
        <div className={css.imgWrapper}>
          <img className={css.ceo} src={ceo} alt={"ceo"} />
          <div>
            <img className={css.banner} src={banner} alt={"banner"} />
            <img className={css.stats} src={stats} alt={"stats"} />
          </div>
        </div>

        <div className={css.imgWrapper}>
        </div>
      </div>

    </div>
  )
}

export default Bam