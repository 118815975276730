import React from 'react';

import { Routes, Route, Link, Outlet} from 'react-router-dom';

import nebula from 'assets/nebula.mp4'
import nebulaPoster from 'assets/nebula.png'

import css from './Event.module.css'
import CompanyInfo from 'components/companyInfo/CompanyInfo';



function Event() {
    return (
        <div className={css.ParallaxVideo} >
            {/* <video src={nebula} webkit-playsinline autoPlay muted loop controls={false} playsinline/> */}
            <img src={nebulaPoster}/>
            <CompanyInfo/>
        </div>
    )
}

export default Event