import { Routes, Route, } from 'react-router-dom';

import Home from './pages/home/Home';
import Privacy from 'pages/privacy/Privacy';
import FAQ from 'pages/faq/FAQ';
import TermsAndConditions from 'pages/termsAndConditions/TermsAndConditions';
import DeleteMyData from 'pages/deleteMyData/DeleteMyData';
// import MadShow3 from 'pages/madShow3/MadShow3';
import Main from 'components/main/Main';
import About from 'pages/about/About';
import Nftnella from 'pages/nftnella/Nftnella';
import Hologram from 'pages/hologram/Hologram';
import EarnPointsInfo from 'pages/earnPointsInfo/EarnPointsInfo';

function App() {

  return <div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="holo" element={<Hologram />} />
      <Route path="nftella1" element={<Nftnella />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="t&c" element={<TermsAndConditions />} />
      <Route path="points" element={<EarnPointsInfo />} />
      <Route path="deleteinfo" element={<DeleteMyData />} />
      <Route path="" element={<DeleteMyData />} />
      {/* <Route path="madshow3" element={<Main />}/> */}
    </Routes>
  </div>
}

export default App;
