import { useNavigate } from "react-router-dom"
import logo from "./muxic_logo.png"
// import html from "./termsAndConditions0904.html"

import css from './TermsAndConditions.module.css'

function TermsAndConditions() {
    const navigate = useNavigate()
    return (
        <div className={css.parallax}>
            <div className={css.wrapper1}>
                <img className={css.logo} src={logo} alt="logo" />
                <div>Terms and Conditions</div>
                <br />
                <p class="c0"><span class="c6">1. Introduction</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Please read these Terms and Conditions (these &quot;Terms&quot;) carefully as they
                    govern your use of Muxic&#39;s personalized services for streaming music and other content, including all of
                    our software applications that incorporate or link to these Terms (collectively, the &quot;Muxic
                    Service&quot;) and any music</span><span class="c4">,</span><span class="c4">&nbsp;or other material that is
                        made available through the Muxic Service (the &quot;Content&quot;).</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Use of the Muxic Service may be subject to additional terms and conditions presented
                    by Muxic, which are hereby incorporated by reference into these Terms.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">By signing up for, or otherwise using, the Muxic Service, you agree to these Terms.
                    If you do not agree to these Terms, then you must not use the Muxic Service or access any Content.</span>
                </p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c6">2. The Muxic Service</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">We currently provide free-of-charge memberships or services that offer personalized
                    music, playlists, and other content recommendations. Streaming is possible on a track-by-track basis or by
                    creating playlists.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c3">Service Limitations and Modifications</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">We strive to keep the Muxic Service operational and provide you with a personalized
                    and immersive audio experience. However, please note the following:</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_2-0 start" start="1">
                    <li class="c0 c7 li-bullet-0"><span class="c4">The Muxic Service may experience temporary interruptions due to
                        technical difficulties, maintenance or testing, updates, or changes in relevant laws and regulatory
                        requirements.</span></li>
                    <li class="c0 c7 li-bullet-0"><span class="c4">We may modify, suspend, or permanently or temporarily stop
                        providing all or part of the Muxic Service, including specific functions and features, as we constantly
                        evolve and improve our services.</span></li>
                    <li class="c0 c7 li-bullet-0"><span class="c4">Muxic has no obligation to provide specific content, and we or
                        applicable owners may remove particular songs, videos, podcasts, and other Content without
                        notice.</span></li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <p class="c0"><span class="c6">3. Your Use of the Muxic Service</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c3">Creating a Muxic Account</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">To use all or part of the Muxic Service, you may be required to create a Muxic
                    account. Your username and password are for personal use only and should be kept confidential. You are
                    responsible for all use, including any unauthorized use, of your username and password.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c3">Your Rights to Use the Muxic Service</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Subject to your compliance with these Terms and any other applicable terms and
                    conditions, we grant you a limited, non-exclusive, revocable permission to make personal, non-commercial use
                    of the Muxic Service and the Content (&quot;Access&quot;). The use of the Muxic Application is only
                    authorized for personal and private use, and any other use, particularly in public premises and businesses,
                    is strictly prohibited.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">The Muxic software applications and the Content are licensed to you and not sold or
                    transferred. Muxic and its licensors retain ownership of all copies of the Muxic software applications and
                    Content, even after installation on your devices.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Muxic has the technical means to detect </span><span class="c4">multiple simultaneous
                    connections</span><span class="c4">&nbsp;on the same account and may provide information to legitimate
                        authorities upon request to identify offenders who violate the law or infringe on third-party rights.</span>
                </p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Any </span><span class="c4">artificial use</span><span class="c4">&nbsp;of a Muxic
                    account, such as artificially or abnormally increasing plays of specific music tracks through automated
                    processes or other means, is strictly prohibited.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c3">Muxic&#39;s Proprietary Rights</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">The Muxic Service and the Content are the property of Muxic or its licensors. All
                    Muxic trademarks, service marks, trade names, logos, domain names, and any other features of the Muxic brand
                    (&quot;Muxic Brand Features&quot;) are the sole property of Muxic or its licensors. These Terms do not grant
                    you any rights to use any Muxic Brand Features, whether for commercial or non-commercial use.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c6">4. Content Experience</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">In any part of the Muxic Service, the Content you access, including its selection and
                    placement, may be influenced by </span><span class="c4">commercial</span><span class="c4">&nbsp;considerations,
                        including Muxic&#39;s agreements with third parties. Some Content licensed,
                        provided, created, or made available by Muxic may incorporate advertising, and Muxic is not responsible for
                        any such advertising.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c6">5. Intellectual Property</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">The structure of the Application, the Muxic Free Service, and all its components
                    (including but not limited to logos, domain names, tracks or videos, and associated elements such as photos,
                    images, texts, and artist biographies) are the exclusive property of MUXIC and/or its licensors (including
                    record and video producers, record companies, and authors&#39; societies).</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">These elements are protected by intellectual property laws, including copyright. You
                    may only use these elements for the purpose of using the Muxic Free Service and in compliance with these
                    Terms. Any total or partial representation of the Application, the Muxic Free Service, and/or its components
                    without the express consent of MUXIC is strictly prohibited and considered a violation of the law.</span>
                </p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">The recordings available on the Muxic Free Service are protected digital files under
                    national and international copyright and neighboring rights. They may only be listened to within a private
                    or family setting. Any use for non-private purposes may result in civil and/or criminal proceedings. Any
                    other use of the recordings, including downloading or attempts to download, transferring or attempts to
                    transfer to a computer or any other device (such as music players), burning or attempts to burn to a CD or
                    any other medium, as well as resale, exchange, or rental, is strictly prohibited.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">You acknowledge that the recordings made available to you within the Application are
                    protected by technical measures implemented by MUXIC to prevent or limit unauthorized use.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c6">6. Problems and Disputes</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c3">Suspending and Terminating the Muxic Service</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">These Terms will remain in effect until terminated by either you or Muxic. Muxic may
                    terminate these Terms (including any additional terms and conditions) or suspend your access to the Muxic
                    Service at any time if we believe you have breached these Terms, if we cease providing the Muxic Service
                    with reasonable notice, or as required by applicable law. If these Terms are terminated or your access is
                    suspended, Muxic shall have no liability or responsibility to you except as expressly provided in these
                    Terms. You may terminate these Terms at any time, but in doing so, you may no longer access or use the Muxic
                    Service.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Please note that certain jurisdictions may not allow the exclusion of implied
                    warranties or limitations on consumer rights, so the exclusions and limitations in this section may not
                    apply to you and will not affect your statutory rights.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c6">7. Governing Law and Jurisdiction</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">Unless mandatory laws in your country of residence require otherwise, these
                    Agreements are governed by and construed in accordance with the laws of the Hong Kong Special Administrative
                    Region. You agree to submit to the non-exclusive jurisdiction of the courts of Hong Kong.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c6">8. Changes</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <p class="c0"><span class="c4">These Terms and Conditions are effective as of 2023. We reserve the right to change
                    these Terms and Conditions without notice.</span></p>
                <p class="c0 c2"><span class="c10"></span></p>
                <ol class="c8 lst-kix_list_4-0 start" start="1">
                    <li class="c0 c13 li-bullet-1"><span class="c6">Prohibited Use of </span><span class="c6">Multiple
                        Accounts</span></li>
                </ol>
                <p class="c0 c11"><span class="c4">Users are prohibited from creating multiple accounts for the purpose of gaming
                    the system or exploiting the &quot;listen to earn&quot; model. Any violation of this rule may result in the
                    suspension or termination of the user&#39;s account.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_4-0" start="2">
                    <li class="c0 c13 li-bullet-1"><span class="c6">Data</span><span class="c6">&nbsp;Management</span></li>
                </ol>
                <p class="c0 c11"><span class="c4">The terms outline how the app handles user data, including data collection,
                    storage, and usage practices. The app is committed to complying with relevant data protection regulations
                    and safeguarding user privacy. Users&#39; personal data will be handled in accordance with the app&#39;s
                    privacy policy, which is available for review. Users also have rights regarding their personal data, as
                    outlined in the privacy policy and applicable data protection laws.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_4-0" start="3">
                    <li class="c0 c13 li-bullet-1"><span class="c6">Account Deletion</span></li>
                </ol>
                <p class="c0 c11"><span class="c4">Users have the option to delete their account if they no longer wish to use the
                    app. It should be noted that deleting an account may result in the loss of earned rewards or points, and
                    users should consider this before proceeding with the account deletion process.</span></p>
                <p class="c0 c2"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_4-0" start="4">
                    <li class="c0 c13 li-bullet-1"><span class="c6">Other Terms and Conditions for Song Uploaders:</span></li>
                </ol>
                <p class="c0 c11"><span class="c4">By uploading songs to the Muxic app, you agree to the following terms and
                    conditions:</span></p>
                <p class="c0 c11 c2"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0 start" start="1">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Ownership and Rights: You confirm that you are the rightful owner
                        of the songs you upload or that you have obtained the necessary licenses, permissions, or rights to
                        upload and distribute the songs on the Muxic app. You retain ownership of the songs while granting Muxic
                        a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, modify, publicly
                        perform, and publicly display the songs solely for the purposes of operating the Muxic app and providing
                        its services.</span></li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="2">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Content Guidelines: You are solely responsible for ensuring that
                        the songs you upload comply with all applicable laws and regulations, including copyright laws. The
                        songs must not infringe upon the intellectual property rights of any third party. Additionally, the
                        songs must not contain any illegal, defamatory, obscene, or offensive content. Muxic reserves the right
                        to remove any songs that violate these guidelines or that are otherwise deemed inappropriate.</span>
                    </li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="3">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Song Metadata: When uploading songs, you must provide accurate
                        and complete metadata, including the song title, artist name, album name (if applicable), and any other
                        relevant information. You must also ensure that the metadata does not infringe upon the rights of any
                        third party or contain misleading or false information.</span></li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="4">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Storage and Availability: Muxic will make reasonable efforts to
                        store and maintain the songs uploaded to the app. However, Muxic does not guarantee the availability,
                        accessibility, or preservation of the uploaded songs. Muxic may delete or remove songs from the app at
                        its discretion, including but not limited to situations where the songs violate the content guidelines
                        or where maintenance or technical issues require their removal.</span></li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="5">
                    <li class="c0 c1 li-bullet-0"><span class="c4">User License: By uploading songs to the Muxic app, you grant
                        other users of the app a non-exclusive, personal, and non-transferable license to stream and listen to
                        the songs solely within the Muxic app. This license does not grant users the right to download,
                        reproduce, distribute, or modify the songs in any way.</span></li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="6">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Indemnification: You agree to indemnify and hold Muxic, its
                        affiliates, and its employees harmless from any claims, damages, losses, or liabilities arising out of
                        or related to the songs you upload, including but not limited to claims of copyright infringement,
                        violation of intellectual property rights, or violation of any applicable laws or regulations.</span>
                    </li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="7">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Termination: Muxic reserves the right to terminate or suspend
                        your ability to upload songs to the app at any time, for any reason, without prior notice. Upon
                        termination, Muxic may remove your uploaded songs from the app.</span></li>
                </ol>
                <p class="c0 c2 c5"><span class="c4"></span></p>
                <ol class="c8 lst-kix_list_6-0" start="8">
                    <li class="c0 c1 li-bullet-0"><span class="c4">Modification of Terms: Muxic reserves the right to modify or
                        update these terms and conditions regarding song uploading at any time. It is your responsibility to
                        review the terms periodically to stay informed about any changes. Your continued use of the Muxic app
                        after the modifications will constitute your acceptance of the revised terms.</span></li>
                </ol>
                <br />
                <div
                    style={{ "cursor": "pointer" }}
                    onClick={() => { navigate("/") }} >Return to muxic.io</div>
                <br />

            </div>
        </div>
    )
}

export default TermsAndConditions