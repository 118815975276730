import React from 'react';

import { Routes, Route, Link, Outlet} from 'react-router-dom';

import holo from 'assets/acg/holo.mp4'

import css from './Hologram.module.css'

function Hologram() {
    return (
        <div className={css.bg}>
            <video className={css.ParallaxVideo} src={holo} autoPlay loop muted controls={false} playsInline/>
        </div>
        
    )
}

export default Hologram