import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  email:null,
  file: null,
}

export const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    updateEmail: (state, actions) => {
      state.email = actions.payload
    },
    updateFile: (state, actions) => {
      state.file = actions.payload
    },
  },
})

export const storeEmail = (email) => async (dispatch) => {
    const reqData = {email}
    const res = await axios.post("https://googlesheetdb-qrfrhizova-an.a.run.app/email", reqData);
    console.log("res", res)
    dispatch(updateEmail(email))
};

export const getFile = () => async (dispatch) => {
  const fileURL = "https://storage.googleapis.com/muxicpublic/sample-15s.mp3"
  dispatch(updateFile(fileURL))
};

export const { 
  updateEmail,
  updateFile,
} = slice.actions

export default slice.reducer