import logo from "./muxic_logo.png"

import css from './EarnPointsInfo.module.css'

function Privacy() {
    return (
        <div className={css.parallax}>
            <div className={css.wrapper1}>
                <img className={css.logo} src={logo} alt="logo" />
                <div>Privacy Policy</div>
                <br />
                <div>App title: Muxic</div>
                <div>Company: Muxic Limited</div>
                <div>Developer: Muxic Limited</div>
                <br />
                <div className={css.topic}>Disclosing the types of personal and sensitive user data our app accesses, collects, uses, and shares; and any parties with which any personal or sensitive user data is shared.</div>
                <div >The Muxic app accesses, collects, and uses user personal data including name, email, phone number, username, user-uploaded content such as profile picture image and music files. These data will not be shared to third parties. No sensitive data is accessed, collected, used, or shared.</div>
                <div className={css.topic}>Secure data handling procedures for personal and sensitive user data.</div>
                <div >All personal data is securely stored on our database. No sensitive data is stored.</div>
                <div className={css.topic}>Developer's data retention and deletion policy.</div>
                <div >Muxic will store all collected data indefinitely. If users wish to delete their data, they could make a request via info@muxic.io</div>
            </div>
        </div>
    )
}

export default Privacy