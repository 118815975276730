import React from 'react'
import kole1 from './kole1.png'
import kole2 from './kole2.png'


import css from './Kole.module.css';

const Kole = () => {
    return (
        <div className={css.wrapper1}>
            <div className={css.wrapper2}>
                <div className={css.title}>《KOL-E》| MUXIC.IO A.I. x VIRTUAL IDOL PROJECT 2023 + PROPRIETARY MO-CAP MODULES</div>
                <div className={css.imgWrapper}>
                    <img className={css.kole1} src={kole1}/>
                    <img className={css.kole2} src={kole2}/>
                </div>

            </div>
        </div>
    )
}

export default Kole