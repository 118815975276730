import { useState } from 'react';
import { useDispatch } from 'react-redux'

import { storeEmail } from 'redux/slice';
import css from './Email.module.css';


function Email() {

  const [email, setEmail] = useState("")
  const [clicked, setClicked] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const dispatch = useDispatch()

  // Define Regrex then match Regrex
  const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  const validEmail = emailRegex.test(email)

  function buttonSwitcher() {
    switch (validEmail) {

      case true: return <button
        onClick={() => {
          dispatch(storeEmail(email));
          setEmail("")
          setConfirmed(true)
          // dispatch(getFile()) 
        }}
        className={css.enableConfirm}>
        CONFIRM</button>

      default: return <button onClick={() => { setClicked(true) }} className={css.disableConfirm}>CONFIRM</button>
    }
  };

  function displayAlert() {
    switch (clicked) {
      case true: return <div className={css.alert}>Please insert email</div>
      default: return null
    }
  };

  function displayConfirmation() {
    switch (confirmed) {
      case true: return <div className={css.thankyou}>Thank you. Submission successful. <br></br>Await for your confirmation email soon.</div>
      default: return null
    }
  };

  console.log(email)
  return <div>
    <div className={css.mainWrapper} >
      <img className={css.logo} src="https://i.imgur.com/ra3u3Wr.png" alt="logo" />
      <div className={css.inputWrapper}>
        <div>Enrol in our upcoming open beta.</div>
        <div>Leave your email below.</div>
        <input
          value={email}
          onClick={() => { setClicked(false) }}
          onChange={(e) => { setEmail(e.target.value) }}
          placeholder='Email'
          spellCheck="false">
        </input>
        <div>{displayAlert()}</div>
        <div>{buttonSwitcher()}</div>
        <div>{displayConfirmation()}</div>
      </div>

    </div>
  </div>
}

export default Email;
