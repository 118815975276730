import React from 'react'
import metaversePoster from "./metaversePoster.jpeg"
import offlinePoster from "./offlinePoster.jpeg"
import soldOut from "./soldOut.png"


import css from './Mr1.module.css';

const Mr1 = () => {
    return (
        <div className={css.wrapper1}>
            <div className={css.wrapper2}>
                <div className={css.textWrapper}>
                    <div className={css.title}>《尹光掂呀! 演唱會 2023》| 環星娛樂</div>
                    <div className={css.title}>HK'S 2nd METAVERSE × REAL LIFE CONCERT EXPERIENCE</div>
                </div>
                <div className={css.imgWrapper}>
                    <img src={metaversePoster}/>
                    <img src={offlinePoster}/>
                </div>
                <img className={css.soldOut} src={soldOut}/>
            </div>




        </div>
    )
}

export default Mr1