import React from 'react'
import banner from './newApp.png'
import listenToEarn from './listenToEarn.png'
import apple from './apple512x512.png'
import android from './google512x512.png'

import css from './NewApp.module.css';

const NewApp = () => {
    return (
        <div className={css.wrapper1}>
            <div className={css.wrapper2}>
                <img src={banner} alt={"banner"} />
                <div className={css.wrapper3}>
                    <a href="https://apps.apple.com/app/muxic/id1624606846/" target="_blank" rel="noreferrer">
                        <img src={apple} alt='apple' />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.muxic.app" target="_blank" rel="noreferrer">
                        <img src={android} alt={"andriod"} />
                    </a>

                </div>

                <img src={listenToEarn} alt={"listenToEarn"} />
            </div>
        </div>
    )
}

export default NewApp