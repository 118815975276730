import React from 'react'
import both from "./both.png"
import logo from "./logo.png"
import rthk from "./RTHK.mp4"
import suzie from "./suize.png"
import wendy from "./wendy.png"

import css from './Spiral.module.css';

const Spiral = () => {
    return (
        <div className={css.wrapper1}>
            <div className={css.wrapper2}>
                <div className={css.wrapper3}>
                    <div className={css.title}>《SPIRAL》| MUXIC.IO HUMAN x VIRTUAL IDOL PROJECT 2022</div>
                    <div className={css.imgWrapper}>
                        <img className={css.logo} src={logo}/>
                        <img className={css.both} src={both}/>
                    </div>
                    <video  width="100px" controls autoPlay loop muted playsInline src={rthk}/>
                    <div className={css.imgWrapper}>
                        <img className={css.suzie} src={suzie}/>
                        <img className={css.wendy} src={wendy}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Spiral