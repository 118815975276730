import logo from "./muxic_logo.png"

import css from './DeleteMyData.module.css'

function DeleteMyData() {
    return (
        <div className={css.parallax}>
            <div className={css.wrapper1}>
                <img className={css.logo} src={logo} alt="logo" />
                <div>Privacy Policy</div>
                <br/>
                <div>App title: Muxic</div>
                <div>Company: Muxic Limited</div>
                <div>Developer: Muxic Limited</div>
                <br/>
                <div className={css.topic}>If you wish to have your account or data removed, please reach out to us and make a request via the email below:</div>
                <div >info@muxic.io</div>
                <div className={css.topic}>The types of data that are deleted or kept, and retention period:</div>
                <div >The Muxic app accesses, collects, and uses user personal data including name, email, phone number, username, user-uploaded content such as profile picture image and music files. These data will not be shared to third parties. No sensitive data is accessed, collected, used, or shared. If no request is made, Muxic will store all collected data indefinitely. </div>
            </div>
        </div>
    )
}

export default DeleteMyData