import React from 'react'
import francis from "./francis.png"
import kenneth from "./kenneth.png"

import css from './Leadership.module.css';

const Leadership = () => {
    return <div className={css.wrapper1}>
        <div className={css.wrapper2}>
            <div>
                <div className={css.title}>LEADERSHIP.</div>
                <div className={css.paragraph}><span className={css.titleInParagraph}>Kenneth Chau </span>is currently serving as the Honorary President of the Hong Kong Science Park Technology Training Network (TIN), dedicated to cultivating Hong Kong's innovative technology companies and promoting the development of the technology industry. Under Kenneth’s leadership, TIN has developed into the largest technology start-up organization in Hong Kong with more than five hundred corporate members. </div>
            </div>
            <div>
                <div className={css.imgWrapper}>
                    <div className={css.Wrapper}>
                        <img src={kenneth} alt="kenneth" />
                        <div>CEO & FOUNDER</div>
                        <div>KENNETH CHAU</div>
                    </div>
                    <div>
                        <img src={francis} alt="francis" />
                        <div>TECHNICAL DIRECTOR</div>
                        <div>PROF. FRANCIS LAU</div>
                    </div>
                </div>
                <div className={css.paragraph}><span className={css.titleInParagraph}>Professor Lau </span>served as the Head of the Department of Computer Science of The University of Hong Kong from 2000-2015, and Associate Dean of Engineering from 2016- 2019. He has more than 30 years of experiences in R&D and application of IT to music. Prof. Lau obtained his PhD in computer science from the University of Waterloo. </div>
            </div>
        </div>
    </div>
}

export default Leadership